.entity{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}
.file
{
    padding: 20px 10px;
}
.mediaImage
{
    max-width: 100px;
    max-height: 100px;
}

.entity:hover{
    background-color: rgba(173, 197, 221, 0.5);
}

.entity span
{
    margin: 5px auto;
    text-align: center;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}